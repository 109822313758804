import React from 'react'
import ResourceCard from './ResourceCard'

function CaseStudies() {
  return (
    <div className="container mt-100 mt-60">
      <div className="row justify-content-center h-auto">
        <div className="col-12 text-center">
          <div className="section-title mb-4 pb-2">
            <h5>Case Studies</h5>
            <h4 className="title mb-4">Success Stories</h4>
            <p className="text-muted para-desc mb-0 mx-auto">
            Read about our successful partnerships and how WellSole technology has made a difference in various industries. Our case studies highlight the impact of our smart insoles on performance, wellness, and overall satisfaction.
            </p>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-4 col-md-6 col-12">
          <ResourceCard
            title={"Foot Health and Wellness: The Role of Smart Insoles"}
            description={
              "Learn about the importance of foot health and how WellSole insoles contribute ..."
            }
            image={"cases/case1.jpg"}
          />
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <ResourceCard
            title={"Enhancing Employee Wellness at ActiveFeet Inc"}
            description={
              "Learn how ActiveFeet Inc. partnered with WellSole to improve workplace ergonomics and ..."
            }
            image={"cases/case2.jpg"}
          />
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <ResourceCard
            title={"Boosting Athletic Performance at TopShoe Co"}
            description={
              "Discover how TopShoe Co. integrated WellSole technology into their athletic footwear line ..."
            }
            image={"cases/case3.jpg"}
          />
        </div>
      </div>
    </div>
  )
}

export default CaseStudies
