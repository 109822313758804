import React from "react";
import Header from "../components/common/Header";
import Hero from "../components/Home/Hero";
import Innovation from "../components/Home/Innovation";
import Whyus from "../components/Home/Whyus";
import Performance from "../components/Home/Performance";
import Cta from "../components/Home/Cta";
import Footer from "../components/common/Footer";
import Endorsements from "../components/Home/Endorsements";

function Home() {

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <Hero />
      <Innovation />
      <Whyus />
      <Performance />
      <Endorsements/>
      <Cta />
      <Footer/>
    </div>
  );
}

export default Home;
