import React from "react";
import Header from "../components/common/Header";
import Cta from "../components/Home/Cta";
import Footer from "../components/common/Footer";
import Hero from "../components/common/Hero";
import Elevate from "../components/Technology/Elevate";
import Wework from "../components/Technology/Wework";
import Research from "../components/Technology/Research";
import Benefits from "../components/Technology/Benefits";
import Soles from "../components/Technology/Soles";

function Technology() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <Hero
        title="TECHNOLOGY"
        heading="Advanced Metrics for Smarter Insoles"
        image={"technology.jpg"}
      />
      <Elevate />
      <Wework />
      <Soles />
      <Benefits />
      <Research />
      <Cta />
      <Footer />
    </div>
  );
}

export default Technology;
