import React from "react";

function Whyus() {
  return (
    <section className="section pt-0">
      <div className="container pb-5 mb-md-5">
        <div className="row justify-content-center mb-5 mt-0">
          <div className="col-12 text-center">
            <div className="section-title mb-4 pb-2">
              <h4 className="title mb-4">Why Partner with WellSole?</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-12">
            <div className="features feature-primary text-center">
              <div className="image position-relative d-inline-block">
                <i className="uil uil-processor h1 text-primary"></i>
              </div>

              <div className="content mt-4">
                <h5>Cutting-Edge Technology</h5>
                <p className="text-muted mb-0">
                  Our smart insoles provide real-time data on performance and
                  wellness metrics, seamlessly integrating with your shoe
                  designs.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-12 mt-5 mt-sm-0">
            <div className="features feature-primary text-center">
              <div className="image position-relative d-inline-block">
                <i className="uil uil-heartbeat h1 text-primary"></i>
              </div>

              <div className="content mt-4">
                <h5>Enhance Your Product Line</h5>
                <p className="text-muted mb-0">
                  Differentiate your brand by offering shoes with advanced
                  health and performance monitoring features.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-12 mt-5 mt-sm-0">
            <div className="features feature-primary text-center">
              <div className="image position-relative d-inline-block">
                <i className="uil uil-comments-alt h1 text-primary"></i>
              </div>

              <div className="content mt-4">
                <h5>Dedicated Support</h5>
                <p className="text-muted mb-0">
                  Our team works closely with you to ensure smooth integration
                  and ongoing support.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Whyus;
