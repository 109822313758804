import React from 'react'
import Section from '../common/Section'

function Workplace() {
  return (
    <div id="workplace" className='mt-100'>
      <Section
        title="Promoting Wellness in the Workplace"
        description={
          "WellSole insoles support employee wellness and ergonomics in office and industrial settings. Our technology helps reduce fatigue, improve posture, and enhance overall comfort"
        }
        image={"assets/images/workplace.jpg"}
      />
      <div className="container mt-100 mt-60">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <div className="section-title mb-4 pb-2">
              <h4 className="title mb-4">
              Applications in the Workplace
              </h4>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-4 col-md-6">
            <div className="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
              <div className="icons text-start">
                <i className="uil uil-accessible-icon-alt rounded h1 mb-0"></i>
              </div>
              <div className="card-body p-0 mt-4">
                <h5 className="title h5 text-dark">Ergonomics</h5>
                <p className="text-muted mt-2">
                Monitor and improve workplace ergonomics to prevent injuries and enhance employee comfort
                </p>
                <i className="uil uil-accessible-icon-alt full-img"></i>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
              <div className="icons text-start">
                <i className="uil uil-compress-arrows rounded h1 mb-0"></i>
              </div>
              <div className="card-body p-0 mt-4">
                <h5 className="title h5 text-dark">Fatigue Reduction</h5>
                <p className="text-muted mt-2">
                Track and manage pressure distribution to reduce fatigue and increase productivity
                </p>
                <i className="uil uil-compress-arrows full-img"></i>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
              <div className="icons text-start">
                <i className="uil uil-processor rounded h1 mb-0"></i>
              </div>
              <div className="card-body p-0 mt-4">
                <h5 className="title h5 text-dark">Wellness Programs</h5>
                <p className="text-muted mt-2">
                Integrate WellSole technology into corporate wellness programs to promote employee health and satisfaction.
                </p>
                <i className="uil uil-processor full-img"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Workplace
