import React from "react";
import { Link } from "react-router-dom";

function Hero() {
  return (
    <section
      className=" w-100 vh-100 position-relative d-flex align-items-end justify-content-center"
      id="home"
    >
      {/* Background Video */}
      <video
        autoPlay
        muted
        loop
        playsInline
        className="position-absolute w-100 h-100 object-cover mobile-video-shift"
        style={{ top: 0, left: 0, zIndex: -1, objectFit: "cover" }}
      >
        <source src="assets/video/hero.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>


      <div className="rounded-5 title-heading text-center studio-home rounded white-transparent-bg mb-3">
        <h2 className=" fw-bolder mb-1">
          Optimize Your Performance and Wellness with <br/>
          <span className="clr">WellSole AI Smart Insoles</span>
        </h2>
        <div className="mt-1 text-center">
          <Link to="/contact" className="btn btn-primary">
            <i className="uil uil-phone"></i> Contact Us
          </Link>
        </div>

      </div>
    </section>
  );
}

export default Hero;
