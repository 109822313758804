import React from "react";

function Elevate() {
  return (
    <div className="container mt-100 mt-60">
      <div className="row justify-content-center">
        <div className="col-12 text-center">
          <div className="section-title mb-4 pb-2">
            <h4 className="title mb-4">Elevate Your Footwear</h4>
            <p className="text-muted para-desc mb-0 mx-auto">
              WellSole offers shoe manufacturers the opportunity to integrate
              WellSole offers shoe manufacturers the opportunity to integrate
              cutting-edge smart technology into their footwear products.
              Enhance your product line, differentiate your brand, and provide
              added value to your customers with our innovative solutions
            </p>
          </div>
        </div>
      </div>
      <div className="row align-items-center mt-5 pb-5">
        <div className="col-lg-5 col-md-6">
          <img
            src="assets/images/elevate.png"
            className="img-fluid rounded-md shadow-lg"
            alt=""
          />
        </div>

        <div className="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
          <div className="section-title text-md-start text-center ms-lg-4">
            <h6>TECHICAL SPECIFICATIONS</h6>
            <h4 className="title mb-4">
              Our smart insoles are packed with state-of-the-art features
              designed to offer precise data and reliable performance. Here are
              the key specifications:
            </h4>
            <div className="row">
              <div className="col-md-6">
                <div className="d-flex align-items-center text-start mt-4 pt-2">
                  <div className="text-primary h4 mb-0 me-3 p-3 rounded-md shadow">
                    <i className="uil uil-processor fs-3"></i>
                  </div>
                  <div className="flex-1">
                    <p className="text-dark h6">Sensors</p>
                    <small className="text-muted">
                      High-precision pressure sensors
                    </small>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex align-items-center text-start mt-4 pt-2">
                  <div className="text-primary h4 mb-0 me-3 p-3 rounded-md shadow">
                    <i className="uil uil-bluetooth-b fs-3"></i>
                  </div>
                  <div className="flex-1">
                    <p className="text-dark h6">Connectivity</p>
                    <small className="text-muted">Bluetooth 5.0 Property</small>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex align-items-center text-start mt-4 pt-2">
                  <div className="text-primary h4 mb-0 me-3 p-3 rounded-md shadow">
                    <i className="uil uil-battery-bolt fs-3"></i>
                  </div>
                  <div className="flex-1">
                    <p className="text-dark h6">Battery Life</p>
                    <small className="text-muted">
                      Up to 30 days on a single charge
                    </small>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex align-items-center text-start mt-4 pt-2">
                  <div className="text-primary h4 mb-0 me-3 p-3 rounded-md shadow">
                    <i className="uil uil-puzzle-piece fs-3"></i>
                  </div>
                  <div className="flex-1">
                    <p className="text-dark h6">Compatibility</p>
                    <small className="text-muted">
                      iOS and Android devices
                    </small>
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-flex justify-content-md-center">
                <div className="d-flex align-items-center text-start mt-4 pt-2">
                  <div className="text-primary h4 mb-0 me-3 p-3 rounded-md shadow">
                    <i className="uil uil-shield-check fs-3"></i>
                  </div>
                  <div className="flex-1">
                    <p className="text-dark h6">Materials</p>
                    <small className="text-muted">
                      Breathable, high-durability materials
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row align-items-center pt-5 mt-5">
        <div className="d-flex justify-content-center col-md-6 ">
          <img
            src="assets/images/pressure.png"
            className="img-fluid w-75 rounded-md shadow-lg"
            alt=""
          />
        </div>

        <div className=" order-md-first order-lg-first col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
          <div className="section-title text-md-start text-center ms-lg-4">
            <h4 className="title mb-4">
            Enhanced Insights with WellSole            
            </h4>
            <p className="text-muted para-desc mb-0 mx-auto">
            WellSole Heat Map tracks Peak Pressures, Average Pressures, Heel Strike, and Toe Off. Monitor your balance, asymmetry in gait, and stride efficiency with detailed data collected from our precision sensors.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Elevate;
