import React from "react";

function Wework() {
  return (
    <div className="container mt-100">
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="section-title text-center mb-4 pb-2">
            <h6 className="text-primary">How It Works</h6>
            <h4 className="title mb-4">Innovative Technology at Work</h4>
            <p className="text-muted para-desc mx-auto mb-0">
              WellSole insoles use a combination of high-precision sensors and
              advanced algorithms to provide accurate and actionable insights.
              Here&apos;s how our technology works:
            </p>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4 mt-4 pt-2">
          <div className="card features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
            <div className="icons text-center mx-auto">
              <i className="uil uil-cloud-check rounded h3 mb-0"></i>
            </div>

            <div className="card-body">
              <h5 className="text-dark">Data Collection</h5>
              <p className="text-muted mb-0">
                Our insoles are equipped with sensors that collect real-time
                data on pressure, movement, and gait
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
          <div className="card features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
            <div className="icons text-center mx-auto">
              <i className="uil uil-data-sharing rounded h3 mb-0"></i>
            </div>

            <div className="card-body">
              <h5 className="text-dark">Data Analysis</h5>
              <p className="text-muted mb-0">
                The collected data is transmitted to our cloud-based platform,
                where advanced algorithms analyze the information to provide
                valuable insights
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2">
          <div className="card features feature-primary feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
            <div className="icons text-center mx-auto">
              <i className="uil uil-comparison rounded h3 mb-0"></i>
            </div>

            <div className="card-body">
              <h5 className="text-dark">Actionable Insights</h5>
              <p className="text-muted mb-0">
                Users receive real-time feedback and personalized
                recommendations through our mobile app, helping them improve
                their performance and wellness
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Wework;
