import React from "react";

function Faq() {
  return (
    <div className="container mt-100 mt-60">
      <div className="row">
        <div className="col-md-12 text-center">
          <div className="section-title mb-4 pb-2">
            <h6>QUESTIONS? WE ARE READY!</h6>
            <h2 className="title mb-4">Common FAQ&apos;s</h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-12">
          <div className="d-flex">
            <i
              className="uil uil-circle icon-ex-md text-primary me-2 mt-1"
            ></i>
            <div className="flex-1">
              <h5 className="mt-0">How long does the integration process take?</h5>
              <p className="answer text-muted mb-0">
                The integration process timeline varies depending on the level
                of customization and specific requirements. Our team will
                provide a detailed timeline during the initial consultation.
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
          <div className="d-flex">
            <i
              className="uil uil-circle icon-ex-md text-primary me-2 mt-1"
            ></i>
            <div className="flex-1">
              <h5 className="mt-0">What kind of support does Wellsole offer</h5>
              <p className="answer text-muted mb-0">
                We offer comprehensive support throughout the integration
                process and beyond, including technical assistance, training,
                and ongoing maintenance
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
