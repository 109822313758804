import React from "react";
import { Link } from "react-router-dom";

function Team() {
  return (
    <div className="container mt-100 mt-60">
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="section-title text-center mb-4 pb-2">
            <h4 className="title mb-4">Meet Our Team</h4>
            <p className="text-muted para-desc mx-auto mb-0">
              Our team is composed of industry experts, engineers, and
              innovators dedicated to advancing footwear technology. Each member
              brings unique skills and experiences, contributing to the
              development of WellSole&apos;s groundbreaking products.
            </p>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-4 col-md-6 mt-4 pt-2">
          <div className="card team team-primary text-center border-0">
            <div className="position-relative">
              <img
                src="assets/images/team/ted.webp"
                className="img-fluid avatar avatar-ex-large rounded-circle shadow"
                alt=""
              />
            </div>
            <div className="card-body py-3 px-0 content">
              <h5 className="mb-0">
                <Link to="##" className="name text-dark">
                  Ted Finn
                </Link>
              </h5>
              <small className="designation text-primary fw-bold">
                Co-Founder & Chairman
              </small>
              <p className="text-muted fw-bold pt-2">
                4x Founder in Footwear / Tech
              </p>
              <p className="text-muted fw-bold">50m+ prs of Shoes</p>
              <p className="text-muted fw-bold">1x Exits</p>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 mt-4 pt-2">
          <div className="card team team-primary text-center border-0">
            <div className="position-relative">
              <img
                src="assets/images/team/johnny.webp"
                className="img-fluid avatar avatar-ex-large rounded-circle shadow"
                alt=""
              />
            </div>
            <div className="card-body py-3 px-0 content">
              <h5 className="mb-0">
                <Link to="##" className="name text-dark">
                  Johnny Cator
                </Link>
              </h5>
              <small className="designation text-primary fw-bold">CEO & Co-founder</small>
              <p className="text-muted fw-bold pt-2">Founder & CEO, Webctel</p>
              <p className="text-muted fw-bold">
                Software Leader & Systems Architect
              </p>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 mt-4 pt-2">
          <div className="card team team-primary text-center border-0">
            <div className="position-relative">
              <img
                src="assets/images/team/greg.webp"
                className="img-fluid avatar avatar-ex-large rounded-circle shadow"
                alt=""
              />
            </div>
            <div className="card-body py-3 px-0 content">
              <h5 className="mb-0">
                <Link to="##" className="name text-dark">
                  Greg McKenna, PMP
                </Link>
              </h5>
              <small className="designation text-primary fw-bold">Co-Founder & COO</small>
              <p className="text-muted fw-bold pt-2">
                Director of Manufacturing, IPS
              </p>
              <p className="text-muted fw-bold">
                Manager Operations, Hitachi
              </p>
              <p className="text-muted fw-bold">Symbol Technologies</p>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 mt-4 pt-2">
          <div className="card team team-primary text-center border-0">
            <div className="position-relative">
              <img
                src="assets/images/team/dana.webp"
                className="img-fluid avatar avatar-ex-large rounded-circle shadow"
                alt=""
              />
            </div>
            <div className="card-body py-3 px-0 content">
              <h5 className="mb-0">
                <Link to="##" className="name text-dark">
                  Dana Stearns, MD
                </Link>
              </h5>
              <small className="designation text-primary fw-bold">
                Chief Medical Officer
              </small>
              <p className="text-muted fw-bold pt-2">Practicing Physician</p>
              <p className="text-muted fw-bold">
                Professor, Harvard Medical School
              </p>
              <p className="text-muted fw-bold">
                Lecturer, Tufts Medical School
              </p>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 mt-4 pt-2">
          <div className="card team team-primary text-center border-0">
            <div className="position-relative">
              <img
                src="assets/images/team/eric.webp"
                className="img-fluid avatar avatar-ex-large rounded-circle shadow"
                alt=""
              />
            </div>
            <div className="card-body py-3 px-0 content">
              <h5 className="mb-0">
                <Link to="##" className="name text-dark">
                  Dr. Eric Greenspan, DC
                </Link>
              </h5>
              <small className="designation text-primary fw-bold">
                VP, Medial Operations
              </small>
              <p className="text-muted fw-bold pt-2">
                Practicing Chiropractor Kinesiology
              </p>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 mt-4 pt-2">
          <div className="card team team-primary text-center border-0">
            <div className="position-relative">
              <img
                src="assets/images/team/kevin.webp"
                className="img-fluid avatar avatar-ex-large rounded-circle shadow"
                alt=""
              />
            </div>
            <div className="card-body py-3 px-0 content">
              <h5 className="mb-0">
                <Link to="##" className="name text-dark">
                  Kevin Brooks
                </Link>
              </h5>
              <small className="designation text-primary fw-bold">Sales</small>
              <p className="text-muted fw-bold pt-2">
                Gait Pattern Foot Alignment Research
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
