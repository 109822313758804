import React from "react";

function Endorsements() {
  return (
    <div className="container mt-100 mt-60">
      <div className="row justify-content-center">
        <div className="col-12 text-center">
          <div className="section-title mb-4 pb-2">
            <h4 className="title mb-4">Industry Leader Endorsements</h4>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mt-4 pt-2">
          <div className="card work-process border-0 rounded shadow">
            <div className="card-body">
              <h4 className="title">–  Dr. M Williams, Director of Dialysis Joslin Diabetes Center</h4>
              <p className="text-muted para">
                <quote>
                  &quot;I strongly support the effort by Innovative Wellness Systems Inc. to develop this Biomedical Smart Insole (WellSole) project and pilot protocols that aim to improve patient adherence, engagements, and outcomes&quot;
                </quote>
              </p>
              <ul className="list-unstyled d-flex justify-content-between mb-0 mt-2">
                <li className="step h1 mb-0 fw-bold">QUOTE 1</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-md-6 mt-4 pt-2">
          <div className="card work-process border-0 rounded shadow">
            <div className="card-body">
              <h4 className="title">– Kamal Jethwani, MD, MPH</h4>
              <p className="text-muted para">
                <quote>
                  &quot;We look forward to collaborating with Innovative Wellness Systems as we collaborate on the BSI / WellSole project and pilot protocols that we believe will improve patient adherence, engagement and outcomes. We are pleased to confirm our support to this important endeavor and look forward to the development of this important pilot study.&quot;
                </quote>
              </p>
              <ul className="list-unstyled d-flex justify-content-between mb-0 mt-2">
                <li className="step h1 mb-0 fw-bold">QUOTE 2</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Endorsements;
