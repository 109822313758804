import React from "react";
import Section from "../common/Section";

function CustomerElectronics() {
  return (
    <div id="customer" className="mt-100">
      <Section
        title="Integrating with Consumer Electronics"
        description={
          "WellSole insoles seamlessly integrate with wearables and smart devices, providing users with comprehensive health and performance data. Our technology opens up new possibilities for partnerships with tech companies"
        }
        image={"assets/images/consumer.jpg"}
      />
      <div className="container mt-100 mt-60">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <div className="section-title mb-4 pb-2">
              <h4 className="title mb-4">
                Applications in Consumer Electronics
              </h4>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-4 col-md-6">
            <div className="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
              <div className="icons text-start">
                <i className="uil uil-watch rounded h1 mb-0"></i>
              </div>
              <div className="card-body p-0 mt-4">
                <h5 className="title h5 text-dark">Wearable Integration</h5>
                <p className="text-muted mt-2">
                  Connect WellSole insoles with popular wearable devices to
                  provide holistic health and performance insights.
                </p>
                <i className="uil uil-watch full-img"></i>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
              <div className="icons text-start">
                <i className="uil uil-mobile-android rounded h1 mb-0"></i>
              </div>
              <div className="card-body p-0 mt-4">
                <h5 className="title h5 text-dark">
                  Smart Device Compatibility
                </h5>
                <p className="text-muted mt-2">
                  Ensure seamless compatibility with smartphones and other smart
                  devices for real-time data access
                </p>
                <i className="uil uil-mobile-android full-img"></i>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
              <div className="icons text-start">
                <i className="uil uil-users-alt rounded h1 mb-0"></i>
              </div>
              <div className="card-body p-0 mt-4">
                <h5 className="title h5 text-dark">
                  Partnership Opportunities
                </h5>
                <p className="text-muted mt-2">
                  Collaborate with tech companies to develop innovative
                  solutions that enhance user experiences.
                </p>
                <i className="uil uil-users-alt full-img"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerElectronics;
