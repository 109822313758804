import React from "react";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import Hero from "../components/common/Hero";
import ContactForm from "../components/Contact/ContactForm";

function Contact() {

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <Hero
        title="CONTACT US"
        heading="Get in Touch with WellSole"
        image={"contact.jpg"}
      />
      
      <ContactForm />
      <Footer />
    </div>
  );
}

export default Contact;
