import React from "react";
import Section from "../common/Section";

function Rehabilitation() {
  return (
    <div id="rehabilitation" className="mt-100">
      <Section
        title="Supporting Rehabilitation Efforts"
        description={
          "WellSole insoles provide crucial support in physical therapy and rehabilitation centers. Our technology aids in the recovery process by offering real-time feedback and detailed progress tracking"
        }
        image={"assets/images/rehabilitation.jpg"}
      />
      <div className="container mt-100 mt-60">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <div className="section-title mb-4 pb-2">
              <h4 className="title mb-4">Applications in Rehabilitation</h4>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-4 col-md-6">
            <div className="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
              <div className="icons text-start">
                <i className="uil uil-history rounded h1 mb-0"></i>
              </div>
              <div className="card-body p-0 mt-4">
                <h5 className="title h5 text-dark">Recovery Monitering</h5>
                <p className="text-muted mt-2">
                  Track patient progress and adherence to rehabilitation
                  protocols with precise data
                </p>
                <i className="uil uil-history full-img"></i>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
              <div className="icons text-start">
                <i className="uil uil-flask rounded h1 mb-0"></i>
              </div>
              <div className="card-body p-0 mt-4">
                <h5 className="title h5 text-dark">Biomechanical Analysis</h5>
                <p className="text-muted mt-2">
                  Analyze gait and movement patterns to ensure proper recovery
                  and prevent re-injury
                </p>
                <i className="uil uil-flask full-img"></i>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
              <div className="icons text-start">
                <i className="uil uil-puzzle-piece rounded h1 mb-0"></i>
              </div>
              <div className="card-body p-0 mt-4">
                <h5 className="title h5 text-dark">Customized Therapy</h5>
                <p className="text-muted mt-2">
                  Tailor rehabilitation programs based on detailed insights and
                  patient-specific data
                </p>
                <i className="uil uil-puzzle-piece full-img"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Rehabilitation;
