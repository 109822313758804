import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer mt-5">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="footer-py-60">
              <div className="row">
                <div className="col-lg-3 col-md-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                  <Link to="/" className="logo-footer">
                    <img src="assets/images/logo.png" height="60" alt="Logo" />
                  </Link>
                  <p className="mt-4">
                    Wellsole is a sustainable footwear brand committed to
                    creating high-quality, comfortable, and eco-friendly shoes.
                    Crafted with responsibly sourced materials like organic
                    cotton, recycled plastics, and natural rubber, their designs
                    blend modern style with a focus on minimizing environmental
                    impact.
                  </p>
                  <ul className="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                    <li className="list-inline-item mb-0 pe-2">
                      <Link to="/" target="_blank" className="rounded">
                        <i
                          className="uil uil-facebook-f align-middle"
                          title="facebook"
                        ></i>
                      </Link>
                    </li>
                    <li className="list-inline-item mb-0 pe-2">
                      <Link to="/" target="_blank" className="rounded">
                        <i
                          className="uil uil-instagram align-middle"
                          title="instagram"
                        ></i>
                      </Link>
                    </li>
                    <li className="list-inline-item mb-0 pe-2">
                      <Link to="/" target="_blank" className="rounded">
                        <i
                          className="uil uil-youtube align-middle"
                          title="twitter"
                        ></i>
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <h5 className="footer-head mt-md-4 mt-0">Quick Links</h5>
                  <ul className="list-unstyled footer-list mt-4">
                    <li>
                      <Link to="/about" className="text-foot">
                        <i className="uil uil-angle-right-b me-1"></i> ABOUT US
                      </Link>
                    </li>
                    <li>
                      <Link to="/technology" className="text-foot">
                        <i className="uil uil-angle-right-b me-1"></i>{" "}
                        TECHNOLOGY
                      </Link>
                    </li>
                    <li>
                      <Link to="/solutions" className="text-foot">
                        <i className="uil uil-angle-right-b me-1"></i> SOLUTIONS
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/industries"
                        className="text-foot"
                      >
                        <i className="uil uil-angle-right-b me-1"></i>{" "}
                        INDUSTRIES
                      </Link>
                    </li>
                    <li>
                      <Link to="/partnership" className="text-foot">
                        <i className="uil uil-angle-right-b me-1"></i> OEM
                        PARTNERSHIP
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/resources" className="text-foot">
                        <i className="uil uil-angle-right-b me-1"></i> RESOURCES
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/contact" className="text-foot">
                        <i className="uil uil-angle-right-b me-1"></i> CONTACT
                        US
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <h5 className="footer-head mt-md-4 mt-0">Legal</h5>
                  <ul className="list-unstyled footer-list mt-4">
                    <li>
                      <Link to="/privacy-policy" className="text-foot">
                        <i className="uil uil-angle-right-b me-1"></i>
                        PRIVACY POLICY
                      </Link>
                    </li>
                    <li>
                      <Link to="/terms-of-service" className="text-foot">
                        <i className="uil uil-angle-right-b me-1"></i>TERMS OF
                        SERVICE
                      </Link>
                    </li>
                    <li>
                      <Link to="/disclaimer" className="text-foot">
                        <i className="uil uil-angle-right-b me-1"></i>{" "}
                        DISCLAIMER
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <h5 className="footer-head mt-md-4 mt-0">Newsletter</h5>
                  <p className="mt-4">
                    Sign up and receive the latest tips via email.
                  </p>
                  <form>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="foot-subscribe mb-3">
                          <label className="form-label">
                            Write your email{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="form-icon position-relative">
                            <i className="uil uil-at icon-sm icons"></i>
                            <input
                              type="email"
                              name="email"
                              id="emailsubscribe"
                              className="form-control ps-5 rounded"
                              placeholder="Your email : "
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="d-grid">
                          <button
                            type="submit"
                            id="submitsubscribe"
                            name="send"
                            className="btn btn-primary"
                          >
                            Subscribe
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-3 footer-bar">
        <div className="container text-center">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="text-center">
                <p className="mb-0">
                  © {new Date().getFullYear()} WellSole. All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
