import React from "react";

function ResourceCard({ title, description, image }) {
  return (
    <div className="card blog blog-primary rounded border-0 shadow overflow-hidden">
      <div className="position-relative">
        <img
          src={`assets/images/${image}`}
          className="card-img-top"
          alt={image}
        />
        <div className="overlay rounded-top"></div>
      </div>
      <div className="card-body content">
        <h5>
          <span className="card-title title text-dark">{title}</span>
        </h5>
        <p className="text-muted">{description}</p>
        <div className="post-meta d-flex justify-content-end mt-3">
          <a href="##" className="text-muted readmore">
            Read More <i className="uil uil-angle-right-b align-middle"></i>
          </a>
        </div>
      </div>
    </div>
  );
}

export default ResourceCard;
