import React from "react";

function Soles() {
  return (
    <div className="container mt-100 mt-60">
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="section-title text-center mb-4 pb-2">
            <h4 className="title mb-4">WellSole Technologies</h4>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
          <div className="features feature-primary feature-clean position-relative">
            <img
              src="assets/images/soles/connect.jpeg"
              className="rounded img-fluid"
              alt=""
            />
            <div className="bg-overlay bg-linear-gradient-2 rounded"></div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12 mt-lg-5 pt-lg-5 mt-4 pt-2">
          <div className="features feature-primary feature-clean position-relative">
            <img
              src="assets/images/soles/clinical.jpeg"
              className="rounded img-fluid"
              alt=""
            />
            <div className="bg-overlay bg-linear-gradient-2 rounded"></div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
          <div className="features feature-primary feature-clean position-relative">
            <img
              src="assets/images/soles/galastic.jpeg"
              className="rounded img-fluid"
              alt=""
            />
            <div className="bg-overlay bg-linear-gradient-2 rounded"></div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
          <div className="features feature-primary feature-clean position-relative">
            <img
              src="assets/images/soles/industrial.jpeg"
              className="rounded img-fluid"
              alt=""
            />
            <div className="bg-overlay bg-linear-gradient-2 rounded"></div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12 mt-lg-5 pt-lg-5 mt-4 pt-2">
          <div className="features feature-primary feature-clean position-relative">
            <img
              src="assets/images/soles/military.jpeg"
              className="rounded img-fluid"
              alt=""
            />
            <div className="bg-overlay bg-linear-gradient-2 rounded"></div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
          <div className="features feature-primary feature-clean position-relative">
            <img
              src="assets/images/soles/performance.jpeg"
              className="rounded img-fluid"
              alt=""
            />
            <div className="bg-overlay bg-linear-gradient-2 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Soles;
