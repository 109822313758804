import React from "react";

function Summary() {
  return (
    <div className="container mt-100 mt-60">
      <div className="row">
        <div className="col-md-12 text-center">
          <div className="section-title mb-4 pb-2">
            <h2 className="title mb-4">Summary of Scientific Studies</h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-12">
          <div className="d-flex">
            <i className="uil uil-circle icon-ex-md text-primary me-2 mt-1"></i>
            <div className="flex-1">
              <h5 className="mt-0">Study 1</h5>
              <p className="answer text-muted mb-0">
                An evaluation by the University of Massachusetts’ Center for
                Human Health and Performance demonstrated the validity and
                reliability of WellSole insoles compared to a gold standard
                pressure measuring system
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
          <div className="d-flex">
            <i className="uil uil-circle icon-ex-md text-primary me-2 mt-1"></i>
            <div className="flex-1">
              <h5 className="mt-0"> Study 2</h5>
              <p className="answer text-muted mb-0">
                Field tests over four weeks showed minimal impact on sensor
                calibration and performance, confirming the durability and
                robustness of WellSole technology
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Summary;
