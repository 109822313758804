import React from "react";

function Process() {
  return (
    <div className="container mt-100 mt-60">
      <div className="row align-items-center">
        <div className="col-lg-6 col--6 col-12">
          <div className="row me-lg-4" id="counter">
            <div className="col-md-6 col-12">
              <div className="row">
                <div className="col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                  <div className="card features feature-primary explore-feature border-0 rounded text-center shadow">
                    <div className="card-body">
                      <div className="icons rounded-circle shadow-lg d-inline-block">
                      <i className="uil uil-user-arrows h1"></i>
                      </div>
                      <h5 className="mt-3 title">Initial Consultation</h5>
                      <p className="text-muted mb-0">
                        Discuss your needs and explore how WellSole technology
                        can enhance your product line
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-12 mt-4 pt-2">
                  <div className="card features feature-primary explore-feature border-0 rounded text-center shadow">
                    <div className="card-body">
                      <div className="icons rounded-circle shadow-lg d-inline-block">
                      <i className="uil uil-shuffle h1"></i>
                      </div>
                      <h5 className="mt-3 title">Customization and Integration</h5>
                      <p className="text-muted mb-0">
                      Tailor our smart insole technology to meet the specific needs of your industry, whether it's for sports, healthcare, military, or the workplace.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-12">
              <div className="row pt-lg-4 mt-lg-4">
                <div className="col-12 mt-4 pt-2">
                  <div className="card features feature-primary explore-feature border-0 rounded text-center shadow">
                    <div className="card-body">
                      <div className="icons rounded-circle shadow-lg d-inline-block">
                      <i className="uil uil-file-check-alt h1"></i>
                      </div>
                      <h5 className="mt-3 title">Testing and Validation</h5>
                      <p className="text-muted mb-0">
                        Conduct thorough testing to ensure the seamless
                        functionality and reliability of WellSole technology
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-12 mt-4 pt-2">
                  <div className="card features feature-primary explore-feature border-0 rounded text-center shadow">
                    <div className="card-body">
                      <div className="icons rounded-circle shadow-lg d-inline-block">
                      <i className="uil uil-headphones-alt h1"></i>
                      </div>
                      <h5 className="mt-3 title">Launch and Support</h5>
                      <p className="text-muted mb-0">
                        Launch your enhanced product line with ongoing support
                        from the WellSole team.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col--6 col-12 mt-4 pt-2 mt-lg-0 pt-lg-0">
          <div className="section-title ms-lg-4">
            <p className="text-primary h2 mb-3">
              <i className="uil uil-apps"></i>
            </p>
            <h4 className="title mb-3">Our Partnership Process</h4>
            <p className="text-muted">
              Partnering with WellSole is a seamless and straightforward
              process. Our team works closely with you to ensure smooth
              integration and ongoing support.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Process;
