import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Technology from "./Pages/Technology";
import Performance from "./Pages/Performance";
import Industries from "./Pages/Industries";
import Contact from "./Pages/Contact";
import Partnership from "./Pages/Partnership";
import Resources from "./Pages/Resources";

function App() {
  useEffect(() => {
    import("bootstrap/dist/js/bootstrap");
  }, []);

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/technology" element={<Technology />} />
        <Route exact path="/solutions" element={<Performance />} />
        <Route exact path="/industries" element={<Industries />} />
        <Route exact path="/partnership" element={<Partnership />} />
        <Route exact path="/resources" element={<Resources />} />
        <Route exact path="/contact" element={<Contact />} />
        {/* <Route exact path="*" element={<Error />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
