import React from "react";
import Header from "../components/common/Header";
import Cta from "../components/Home/Cta";
import Footer from "../components/common/Footer";
import Heading from "../components/Resources/Heading";
import Hero from "../components/common/Hero";
import Blogs from "../components/Resources/Blogs";
import CaseStudies from "../components/Resources/CaseStudies";
import Whitepapers from "../components/Resources/Whitepapers";

function Resources() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <Hero
        title="RESOURCES"
        heading="Insights and Innovations"
        image={"resources.jpeg"}
      />
      <Heading />
      <Blogs />
      <CaseStudies />
      <Whitepapers />
      <Cta />
      <Footer />
    </div>
  );
}

export default Resources;
