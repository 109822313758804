import React from "react";

function Heading() {
  return (
    <div className="container mt-100 pt-0">
      <div className="row justify-content-center">
        <div className="col-12 text-center">
          <div className="section-title mb-4 pb-2">
            <h4 className="title mb-4">Discover Smart Insole Insights</h4>
            <p className="text-muted para-desc mb-0 mx-auto">
              Explore our resources to stay informed about the latest
              developments in smart insole technology and the footwear industry.
              Whether you&apos;re looking for expert insights, success stories,
              or in-depth research, WellSole has you covered.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Heading;
