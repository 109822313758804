import React from "react";

function Healthcare() {
  return (
    <>
      <div className="container mt-100 pt-0 overflow-hidden">
        <div className="row justify-content-center align-items-center pb-md-5 pb-0">
          <div className="col-md-12">
            <h2 className="text-center mb-4">INTRODUCTION</h2>
          </div>
          <div className="col-md-6 text-center order-2 order-md-1">
            <div className="section-title mb-4 pb-2">
              <h4 className="title mb-4">
                Transforming Industries with WellSole
              </h4>
              <p className="text-muted para-desc mb-0 mx-auto">
                Discover how WellSole&apos;s smart technology can transform
                various industries and applications. From healthcare and sports
                to workplace wellness and rehabilitation, our innovative
                solutions provide valuable insights and enhance performance and
                health
              </p>
            </div>
          </div>
          <div className="col-md-6 order-1 order-md-2 mb-4">
            <img
              src="assets/images/healthcare.jpg"
              className="img-fluid mx-auto d-block rounded shadow"
              alt=""
            />
          </div>
          <div className="col-md-12 my-4 d-flex justify-content-center gap-4 order-3 flex-md-nowrap flex-wrap text-nowrap">
            <a href="#healthcare" className="btn btn-lg btn-primary w-100">Healthcare</a>
            <a href="#sports" className="btn btn-lg btn-primary w-100">
              Sports and Athletics
            </a>
            <a href="#workplace" className="btn btn-lg btn-primary w-100">Workplace</a>
            <a href="#rehabilitation" className="btn btn-lg btn-primary w-100">Rehabilitation</a>
            <a href="#customer" className="btn btn-lg btn-primary w-100">
              Customer Electronics
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Healthcare;
