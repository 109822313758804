import React from "react";

function Models() {
  return (
    <div className="container-fluid p-5" style={{ background: "url('assets/images/models.jpg') center center" }}>
      <div className="row justify-content-center">
        <div className="col-12 text-center">
          <div className="section-title mb-4 pb-2">
            <h4 className="title mb-4">Flexible Partnership Models</h4>
            <p className="text-muted para-desc mb-0 mx-auto">
              We offer flexible partnership models to suit the unique needs of
              our OEM partners. Whether you are looking for a full integration
              of our technology or specific customization options, WellSole has
              a solution for you.
            </p>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
          <div className="card bg-light rounded shadow border-0">
            <div className="card-body py-5">
              <i className="uil uil-500px h2 text-primary"></i>
              <div className="mt-2">
                <h5 className="card-title">
                  <span className="text-primary">
                    Full Integration
                  </span>
                </h5>
                <p className="text-muted mt-3 mb-0">
                  Embed WellSole technology directly into your shoe designs for
                  comprehensive health and performance monitoring
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
          <div className="card bg-light rounded shadow border-0">
            <div className="card-body py-5">
              <i className="uil uil-atom h2 text-primary"></i>
              <div className="mt-2">
                <h5 className="card-title">
                  <span className="text-primary">
                    {" "}
                    Customization
                  </span>
                </h5>
                <p className="text-muted mt-3 mb-0">
                  Choose specific features and customization options to align
                  with your brand and product requirements.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
          <div className="card bg-light rounded shadow border-0">
            <div className="card-body py-5">
              <i className="uil uil-shield-check h2 text-primary"></i>
              <div className="mt-2">
                <h5 className="card-title">
                  <span className="text-primary">
                    White Label Solutions
                  </span>
                </h5>
                <p className="text-muted mt-3 mb-0">
                  Offer WellSole technology under your own brand name with our
                  white label solutions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Models;
