import React from "react";

function Backed() {
  return (
    <div className="container mt-100 pt-5 overflow-hidden">
      <div className="row align-items-center">
        <div className="col-lg-5 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
          <div className="section-title me-lg-4">
            <h1 className="title mb-3">Backed by Scientific Research</h1>
            <p className="para-desc text-muted">
              WellSole&apos;s technology is validated by extensive scientific
              research. Our insoles have been tested in both lab and real-world
              settings to ensure their accuracy, reliability, and effectiveness
              in enhancing performance and promoting wellness.
            </p>
          </div>
        </div>

        <div className="col-lg-7 order-1 order-lg-2">
          <div className="saas-feature-shape-right position-relative">
            <img
              src="assets/images/backed.jpg"
              className="img-fluid mx-auto d-block rounded shadow"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Backed;
