import React from "react";
import ResourceCard from "./ResourceCard";

function Blogs() {
  return (
    <div className="container mt-100 mt-60">
      <div className="row justify-content-center h-auto">
        <div className="col-12 text-center">
          <div className="section-title mb-4 pb-2">
            <h5>Blogs</h5>
            <h4 className="title mb-4">Insights and Innovations</h4>
            <p className="text-muted para-desc mb-0 mx-auto">
              Stay updated with the latest trends, insights, and innovations in
              smart insole technology and the footwear industry. Our blog
              features expert articles on foot health, performance enhancement,
              and more.
            </p>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-4 col-md-6 col-12">
          <ResourceCard
            title={"Foot Health Tips for Everyday Wellness"}
            description={
              "Learn practical tips for maintaining foot health and how WellSole insoles can ..."
            }
            image={"blogs/blog1.jpg"}
          />
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <ResourceCard
            title={"The Science Behind WellSole Technology"}
            description={
              "Explore the advanced technology that powers WellSole insoles and learn ..."
            }
            image={"blogs/blog2.jpg"}
          />
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <ResourceCard
            title={"How Smart Insoles Are Revolutionizing Athletic Performance"}
            description={
              "Discover how WellSole smart insoles are helping athletes optimize their training ..."
            }
            image={"blogs/blog3.jpg"}
          />
        </div>
      </div>
    </div>
  );
}

export default Blogs;
