import React from "react";

function Research() {
  return (
    <div className="container mt-100 pt-0 overflow-hidden">
      <div className="row align-items-center">
        <div className="col-lg-5 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
          <div className="section-title me-lg-4">
            <h1 className="title mb-3">Validated by Scientific Research</h1>
            <p className="para-desc text-muted">
              The University of Massachusetts&apos; Center for Human Health and
              Performance conducted an evaluation of WellSole’s validity and
              reliability against a gold standard pressure measuring floor tile
              system. The study combined ground truth pressure data from
              mechanical testing with human subject testing in both lab and
              real-world settings. Over a four-week field period, test subjects
              wore pressure-calibrated WellSole insoles. The results
              demonstrated minimal impact from wear on sensor calibration,
              sensitivity, and responsiveness, with WellSole maintaining its
              structural integrity and electronics platform performance.
            </p>
          </div>
        </div>

        <div className="col-lg-7 order-1 order-lg-2">
          <div className="saas-feature-shape-right position-relative">
            <img
              src="assets/images/research.jpg"
              className="img-fluid mx-auto d-block rounded shadow"
              alt="Research"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Research;
