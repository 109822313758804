import React from "react";

function Prioritize() {
  return (
    <div className="container mt-100 pt-5 overflow-hidden">
      <div className="row align-items-center">
        <div className="col-lg-5 order-1 order-lg-2 mt-4 pt-2 mt-lg-0 pt-lg-0">
          <div className="section-title me-lg-4">
            <h1 className="title mb-3">Prioritize Your Wellness</h1>
            <p className="para-desc text-muted">
              At WellSole, we believe in promoting overall wellness through
              innovative technology. Our smart insoles not only enhance
              performance but also support everyday health by monitoring key
              metrics and providing personalized recommendations
            </p>
          </div>
        </div>

        <div className="col-lg-7 order-2 order-lg-1">
          <div className="saas-feature-shape-left position-relative">
            <img
              src="assets/images/prioritize.jpg"
              className="img-fluid mx-auto d-block rounded shadow"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Prioritize;
