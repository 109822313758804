import React from "react";
import { Link } from "react-router-dom";

function Cta() {
  return (
    <div className="container mt-100 mt-60">
      <div className="rounded bg-primary bg-gradient p-lg-5 p-4">
        <div className="row align-items-end">
          <div className="col-md-8">
            <div className="section-title text-md-start text-center">
              <h4 className="title mb-3 text-white title-dark">
                Discover the WellSole Difference
              </h4>
              <p className="text-white-50 mb-0">
                Join the growing number of athletes and health-conscious
                individuals who trust WellSole for their performance and
                wellness needs. Experience the benefits of smart technology
                integrated into your footwear.
              </p>
            </div>
          </div>

          <div className="col-md-4 mt-4 mt-sm-0">
            <div className="text-md-end text-center">
              <Link to="/contact" className="btn btn-light">
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cta;
