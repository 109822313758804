import React from "react";

function Award() {
  return (
    <section
      className="section bg-cta jarallax"
      data-jarallax
      data-speed="0.5"
      style={{
        background: "url('assets/images/award.jpg') center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      id="cta"
    >
      <div className="bg-overlay"></div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <div className="section-title">
              <h4 className="title title-dark text-white mb-4">
                Awards and Recognition
              </h4>
              <p className="text-white-50 para-dark para-desc mx-auto">
                WellSole&apos;s innovative approach and commitment to quality
                have earned us numerous awards and recognition in the industry.
                These accolades validate our efforts and motivate us to keep
                pushing the boundaries of footwear technology.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Award;
