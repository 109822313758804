import React from "react";

function Section({ title, description, image }) {
  return (
    <section
      className="section bg-cta jarallax container-fluid"
      data-jarallax
      data-speed="0.5"
      style={{
        background: `url(${image}) center / cover no-repeat`,
        backgroundAttachment: "fixed",
        backgroundPosition: "center bottom",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      id="cta"
    >
      <div className="bg-overlay"></div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 text-start">
            <div className="section-title">
              <h4 className="title title-dark text-white mb-4">{title}</h4>
              <p className="text-white-50 para-dark para-desc">{description}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section;
