import React from "react";
import Header from "../components/common/Header";
import Cta from "../components/Home/Cta";
import Footer from "../components/common/Footer";
import Hero from "../components/common/Hero";
import Partner from "../components/Partnership/Partner";
import Benefits from "../components/Technology/Benefits";
import Process from "../components/Partnership/Process";
import Faq from "../components/Partnership/Faq";
import Models from "../components/Partnership/Models";

function Partnership() {

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <Hero
        title="OEM PARTNERSHIP"
        heading="Partner with WellSole"
        image={"partnership.jpg"}
      />
      <Partner />
      <Benefits />
      <Models/>
      <Process />
      <Faq />
      <Cta />
      <Footer />
    </div>
  );
}

export default Partnership;
