import React from 'react'
import ResourceCard from './ResourceCard'

function Whitepapers() {
  return (
    <div className="container mt-100 mt-60">
      <div className="row justify-content-center h-auto">
        <div className="col-12 text-center">
          <div className="section-title mb-4 pb-2">
            <h5>Whitepapers</h5>
            <h4 className="title mb-4">In-Depth Research and Analysis</h4>
            <p className="text-muted para-desc mb-0 mx-auto">
            Gain access to comprehensive research and detailed analysis on smart insole technology, performance enhancement, and foot health. Our whitepapers provide valuable insights for industry professionals and enthusiasts alike.
            </p>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-4 col-md-6 col-12">
          <ResourceCard
            title={"Advanced Technology in Footwear: A Comprehensive Guide"}
            description={
              "Explore the latest advancements in smart insole technology and how they are ..."
            }
            image={"whitepapers/whitepaper1.jpg"}
          />
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <ResourceCard
            title={"The Impact of Smart Insoles on Athletic Performance"}
            description={
              "An in-depth analysis of how WellSole smart insoles improve athletic performance through ..."
            }
            image={"whitepapers/whitepaper2.jpg"}
          />
        </div>
        <div className="col-lg-4 col-md-6 col-12">
        </div>
      </div>
    </div>
  )
}

export default Whitepapers
