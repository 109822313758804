import React from "react";

function HealthMoniter() {
  return (
    <div className="container mt-100 mt-60">
      <div className="row justify-content-center">
        <div className="col-12 text-center">
          <div className="section-title mb-4 pb-2">
            <h4 className="title mb-4">Health Monitoring Features</h4>
          </div>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-lg-4 col-md-6">
          <div className="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
            <div className="icons text-start">
              <i className="uil uil-shield-check rounded h1 mb-0"></i>
            </div>
            <div className="card-body p-0 mt-4">
              <h5 className="title h5 text-dark">Posture Correction</h5>
              <p className="text-muted mt-2">
                Receive alerts to correct your posture and prevent long-term
                health issues.
              </p>
              <i className="uil uil-shield-check full-img"></i>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6">
          <div className="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
            <div className="icons text-start">
              <i className="uil uil-compress-arrows rounded h1 mb-0"></i>
            </div>
            <div className="card-body p-0 mt-4">
              <h5 className="title h5 text-dark">Pressure Distribution</h5>
              <p className="text-muted mt-2">
                Monitor pressure distribution to alleviate pain and discomfort.
              </p>
              <i className="uil uil-compress-arrows full-img"></i>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6">
          <div className="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
            <div className="icons text-start">
              <i className="uil uil-compass rounded h1 mb-0"></i>
            </div>
            <div className="card-body p-0 mt-4">
              <h5 className="title h5 text-dark">Activity Tracking</h5>
              <p className="text-muted mt-2">
                Track your daily activities and stay motivated to maintain a
                healthy lifestyle.
              </p>
              <i className="uil uil-compass full-img"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HealthMoniter;
