import React from "react";

function Performance() {
  return (
    <div className="container-fluid pt-0 overflow-hidden" style={{ background: "url('assets/images/bg-performance.png') center bottom", backgroundSize: 'cover' }}>
      <div className="row align-items-center">
        <div className="col-12 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
          <div className="section-title d-flex flex-column align-items-center me-lg-4 text-center py-5">
            <h1 className="text-white mb-3">Proven Performance and Reliability</h1>
            <p className=" text-white text-center">
              The University of Massachusetts&apos; Center for Human Health and
              Performance conducted an evaluation of WellSole&apos;s validity and
              reliability against a gold standard pressure measuring floor tile
              system. The study combined ground truth pressure data from
              mechanical testing with human subject testing in both lab and
              real-world settings. Over a four-week field period, test subjects
              wore pressure-calibrated WellSole insoles. The results
              demonstrated minimal impact from wear on sensor calibration,
              sensitivity, and responsiveness, with WellSole maintaining its
              structural integrity and electronics platform performance.
            </p>
          </div>
        </div>

       
      </div>
    </div>
  );
}

export default Performance;
