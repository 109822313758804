import React from "react";
import { Link } from "react-router-dom";

function Header() {
  const toggleMenu = () => {
    document.getElementById("isToggle").classList.toggle("open");
    var isOpen = document.getElementById("navigation");
    if (isOpen.style.display === "block") {
      isOpen.style.display = "none";
    } else {
      isOpen.style.display = "block";
    }
  };

  return (
    <header id="topnav" className="defaultscroll sticky navbar-white-bg">
      <div className="container-fluid">
        <Link className="logo ms-md-5 ms-0" to="/">
          <img
            src="assets/images/logo.png"
            height="50"
            className="logo-light-mode"
            alt="Logo"
          />
        </Link>

        <div className="menu-extras">
          <div className="menu-item">
            <Link className="navbar-toggle" id="isToggle" onClick={toggleMenu}>
              <div className="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </Link>
          </div>
        </div>

        <div id="navigation">
          <ul className="navigation-menu">
            <li
              className={window.location.pathname === "/about" ? "active" : ""}
            >
              <Link to="/about" className="sub-menu-item">
                ABOUT US
              </Link>
            </li>
            <li
              className={
                window.location.pathname === "/technology" ? "active" : ""
              }
            >
              <Link to="/technology" className="sub-menu-item">
                TECHNOLOGY
              </Link>
            </li>
            <li
              className={
                window.location.pathname === "/solutions" ? "active" : ""
              }
            >
              <Link to="/solutions" className="sub-menu-item">
                SOLUTIONS
              </Link>
            </li>
            <li
              className={
                window.location.pathname === "/industries"
                  ? "active"
                  : ""
              }
            >
              <Link to="/industries" className="sub-menu-item">
                INDUSTRIES
              </Link>
            </li>
            <li
              className={
                window.location.pathname === "/partnership" ? "active" : ""
              }
            >
              <Link to="/partnership" className="sub-menu-item">
                OEM PARTNERSHIP
              </Link>
            </li>
            {/* <li
              className={
                window.location.pathname === "/resources" ? "active" : ""
              }
            >
              <Link to="/resources" className="sub-menu-item">
                RESOURCES
              </Link>
            </li> */}
            <li
              className={
                window.location.pathname === "/contact" ? "active" : ""
              }
            >
              <Link to="/contact" className="sub-menu-item">
                CONTACT US
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}

export default Header;
