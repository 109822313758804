import React from "react";
import Section from "../common/Section";

function HealthcareApplications() {
  return (
    <div id="healthcare">
      <Section
        title={"Revolutionizing Healthcare with Smart Insoles"}
        description={
          "WellSole smart insoles provide healthcare professionals with valuable insights to improve patient outcomes. Our technology helps manage chronic conditions, monitor patient progress, and support rehabilitation efforts."
        }
        image={"assets/images/healthcare.jpg"}
      />
      <div className="container mt-100 mt-60">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <div className="section-title mb-4 pb-2">
              <h4 className="title mb-4">Applications in Healthcare</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-12">
            <div className="card features feature-primary feature-full-bg rounded p-4 bg-light position-relative overflow-hidden border-0">
              <span className="h1 icon-color">
                <i className="uil uil-monitor-heart-rate"></i>
              </span>
              <div className="card-body p-0 content">
                <h5>Chronic Condition Management</h5>
                <p className="para text-muted mb-0">
                  Monitor and manage conditions such as diabetes and arthritis
                  with real-time pressure and gait analysis
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div className="card features feature-primary feature-full-bg rounded p-4 bg-light position-relative overflow-hidden border-0">
              <span className="h1 icon-color">
                <i className="uil uil-life-ring"></i>
              </span>
              <div className="card-body p-0 content">
                <h5>Rehabilitation Support</h5>
                <p className="para text-muted mb-0">
                  Aid in the recovery process by providing detailed feedback on
                  patient progress and adherence to rehabilitation protocols.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
            <div className="card features feature-primary feature-full-bg rounded p-4 bg-light position-relative overflow-hidden border-0">
              <span className="h1 icon-color">
                <i className="uil uil-chart-pie"></i>
              </span>
              <div className="card-body p-0 content">
                <h5>Clinical Insights</h5>
                <p className="para text-muted mb-0">
                  Equip healthcare providers with accurate data to make informed
                  decisions and tailor treatment plans.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HealthcareApplications;
