import React from 'react'
import Header from '../components/common/Header'
import Cta from '../components/Home/Cta'
import Footer from '../components/common/Footer'
import Hero from '../components/common/Hero'
import Summary from '../components/Performance/Summary'
import Backed from '../components/Performance/Backed'
import Prioritize from '../components/Performance/Prioritize'
import Features from '../components/Performance/Features'
import HealthMoniter from '../components/Performance/HealthMoniter'

function Performance() {

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header/>
      <Hero
        title="SOLUTIONS"
        heading="Unlock Your Performance Potential"
        image={"performance.jpg"}
      />
      <Features/>
      <Prioritize/>
      <HealthMoniter/>
      <Backed/>
      <Summary/>
      <Cta/>
      <Footer/>
    </div>
  )
}

export default Performance
