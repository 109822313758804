import React from "react";
import Header from "../components/common/Header";
import Cta from "../components/Home/Cta";
import Footer from "../components/common/Footer";
import Hero from "../components/common/Hero";
import Healthcare from "../components/Industries/Healthcare";
import HealthcareApplications from "../components/Industries/HealthcareApplications";
import AthleticPerformance from "../components/Industries/AthleticPerformance";
import Workplace from "../components/Industries/Workplace";
import Rehabilitation from "../components/Industries/Rehabilitation";
import CustomerElectronics from "../components/Industries/CustomerElectronics";

function Industries() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <Hero
        title="INDUSTRIES"
        heading="Revolutionizing Industries with Smart Technology"
        image={"industries.jpg"}
      />
      <Healthcare />
      <HealthcareApplications />
      <AthleticPerformance />
      <Workplace />
      <Rehabilitation />
      <CustomerElectronics />
      <Cta />
      <Footer />
    </div>
  );
}

export default Industries;
